<template>
  <div class="z-10 top-0" :key="this.closeForm">
    <div
      :class="`flex flex-wrap justify-between main_header route_table_header`"
    >
      <div class="col-start-1 col-end-3">
        <button
          @click.prevent="this.$emit('openForm')"
          class="rounded group body__header--button text-white sm:text-sm lg:text-sm xl:text-sm md:text-sm inline-flex items-center text-sm font-medium shadow p-2 mr-0"
        >
          <span class="uppercase">{{ this.buttonTitle }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  name: "ElementGroupHeader",
  props: ["buttonTitle"],
  data() {
    return {
      closeForm: false,
    };
  },
  components: {
    ChevronDownIcon,
  },
};
</script>

<style>
.route_table_header {
  padding: 10px 40px 10px 40px;
}
</style>
