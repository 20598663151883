<template>
 <ExpertStatisticsTemplate>
  <template v-slot:content >
   <PbxHeader
    @openForm="
     (this.openForm = !this.openForm),
      (this.openUpdateForm = false),
      (groupId = null),
      getElementGroups()
    "
    :buttonTitle="$t('pbx.newGroup')"
    :pbxMenuType="pbxMenuType" />
   <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess" />
   <div class="" v-if="this.openForm">
    <div class="pt-5 align-middle min-w-full sm:px-6 lg:px-10">
     <div class="overflow-hidden shadow">
      <CreateNewElementGroup
       :key="urlType"
       @closeForm="
        (this.openForm = false),
         (selectedPbxElements.length = 0),
         (groupId = null),
         getElementGroups()
       "
       :element="urlType"
       @activeBanner="(activeBanner = true), getElementGroups(), (key = !key)"
       @successMsg="successMsg = $event"
       @success="isSuccess = $event"
       @clearForm="selectedPbxElements.length = 0"
       :elementsData="
        urlType === 'extension'
         ? elementsDataExtensions
         : urlType === 'queue'
         ? elementsDataQueues
         : elementsDataDIDs
       "
       :selectedPbxElements="selectedPbxElements"
       :addElement="addPbxElement"
       :isEdit="false" />
     </div>
    </div>
   </div>
   <div
    class="flex flex-col"
    v-if="this.openUpdateForm && this.groupName.length > 0">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
     <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
      <div class="overflow-hidden shadow">
       <CreateNewElementGroup
        :key="urlType"
        @closeForm="
         (this.openUpdateForm = false), (groupId = null), getElementGroups()
        "
        :element="urlType"
        :name="this.groupName"
        :selectedPbxElements="this.selectedPbxElements"
        :selectedElementsLength="this.selectedElementsLength"
        @activeBanner="(activeBanner = true), getElementGroups(), (key = !key)"
        @successMsg="successMsg = $event"
        @success="isSuccess = $event"
        @clearForm="selectedPbxElements.length = 0"
        :elementsData="
         urlType === 'extension'
          ? elementsDataExtensions
          : urlType === 'queue'
          ? elementsDataQueues
          : elementsDataDIDs
        "
        :addElement="addPbxElement"
        :isEdit="true"
        :groupId="groupId" />
      </div>
     </div>
    </div>
   </div>
   <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
     <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
      <div
       v-if="
        (urlType === 'extension' && this.elementGroups.length > 0) ||
        (urlType === 'queue' && this.elementGroupsQueues.length > 0) ||
        (urlType === 'did' && this.elementGroupsDIDs.length > 0)
       "
       class="overflow-hidden shadow border-b border-gray-200 rounded-md">
       <TablePagination
        :elementsData="
         urlType === 'extension'
          ? elementsDataExtensions
          : urlType === 'queue'
          ? elementsDataQueues
          : elementsDataDIDs
        "
        :tableData="
         urlType === 'extension'
          ? elementsDataExtensions
          : urlType === 'queue'
          ? elementsDataQueues
          : elementsDataDIDs
        "
        @sort="sort($event)"
        @openEditForm="
         (...args) => {
          (this.groupName = args[0]),
           (this.groupId = args[2]),
           this.openEditForm(...args);
         }
        "
        @activeBanner="(activeBanner = true), getElementGroups(), (key = !key)"
        @successMsg="successMsg = $event"
        @success="isSuccess = $event" />
      </div>
      <div v-else class="flex justify-center pt-20">
       <div class="text-center">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="mx-auto h-12 w-12 text-gray-400"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor">
         <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
        <h3 class="mt-2 text-lg font-medium text-gray-900">
         {{ $t("warning.noDataFoundTitle") }}
        </h3>
        <p class="mt-1 text-base text-gray-500">
         {{ $t("warning.noDataFoundElementGroup") }}
        </p>
        <!-- <div class="mt-6">
              <button
                type="button"
                class="rounded inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white body__header--button"
                @click="this.openForm = !this.openForm"
              >
                <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                New Group
              </button>
            </div> -->
       </div>
      </div>
     </div>
    </div>
   </div>
  </template>
 </ExpertStatisticsTemplate>
 <loading
  v-model:active="isLoading"
  :can-cancel="false"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import ExpertStatisticsTemplate from "./ExpertStatisticsTemplate.vue";
import axios from "axios";
import { ExclamationIcon, PlusIcon } from "@heroicons/vue/solid";

import PbxHeader from "../../components/PBX/PbxHeader.vue";
import TablePagination from "../../components/TablePagination.vue";
import ElementGroupHeader from "../../components/PBX/ElementGroupHeader.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import UpdateElementGroupForm from "../../components/PBX/UpdateElementGroupForm.vue";
import CreateNewElementGroup from "../../components/PBX/CreateNewElementGroup.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";

const account = localStorage.getItem("account");
const pbxMenuType = "groups";

export default {
 name: "CallQueuesGroups",
 components: {
  ExclamationIcon,
  PlusIcon,
  TablePagination,
  ElementGroupHeader,
  Loading,
  SearchFilter,
  CreateNewElementGroup,
  UpdateElementGroupForm,
  SuccessBanner,
  PbxHeader,
  ExpertStatisticsTemplate,
 },
 props: ["urlType"],
 data() {
  return {
   groupId: null,
   pbxMenuType,
   elementGroups: [],
   elementGroupsDIDs: [],
   elementGroupsQueues: [],
   elementsDataExtensions: {},
   elementsDataDIDs: {},
   elementsDataQueues: {},
   account,
   isLoading: false,
   fullPage: false,
   search: "",
   openForm: false,
   currentSort: "id",
   currentSortDir: "asc",
   openUpdateForm: false,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   groupName: "",
   selectedPbxElements: [],
   selectedElementsLength: 0,
  };
 },
 methods: {
  addPbxElement(element) {
   let value = element && element.value ? element.value : null;

   if (!this.openUpdateForm) {
    if (element) {
     if (this.selectedPbxElements.length > 0) {
      if (
       this.selectedPbxElements.indexOf(element) >= 0 ||
       this.selectedPbxElements.indexOf(parseInt(element)) >= 0
      ) {
       this.selectedPbxElements.splice(
        this.selectedPbxElements.indexOf(element) >= 0
         ? this.selectedPbxElements.indexOf(element)
         : this.selectedPbxElements.indexOf(parseInt(element)),
        1
       );
      } else {
       this.selectedPbxElements.push(element);
      }
     } else {
      this.selectedPbxElements.push(element);
     }
    }
   } else {
    if (element) {
     if (this.selectedPbxElements.length > 0) {
      if (
       this.selectedPbxElements.indexOf(element.value) >= 0 ||
       this.selectedPbxElements.indexOf(parseInt(element.value)) >= 0
      ) {
       this.selectedPbxElements.splice(
        this.selectedPbxElements.indexOf(element.value) >= 0
         ? this.selectedPbxElements.indexOf(element.value)
         : this.selectedPbxElements.indexOf(parseInt(element.value)),
        1
       );
      } else {
       this.selectedPbxElements.push(element.value);
      }
     } else {
      this.selectedPbxElements.push(element.value);
     }
    }
   }
  },
  openEditForm(...args) {
   // this.groupName = args[0];
   this.selectedPbxElements = JSON.parse(args[1]);
   this.selectedElementsLength = JSON.parse(args[1]).length;
   this.openForm = false;
   this.openUpdateForm = true;
   window.scrollTo(0, 100);
  },
  async getElementGroups() {
   let objectData = undefined;
   try {
    const res = await axios.get(
     `${localStorage.getItem("apiUrl")}/api/v1.2/${
      this.hostName
     }/resource-groups`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     }
    );

    objectData = res.data;
    this.$emit("setLoading", false);
    for (let index = 0; index < objectData.length; index++) {
     const element = objectData[index];
     this.elementsDataQueues = objectData.filter(data => data.type == 4);
     this.elementGroupsQueues.push(element.name);
     this.elementGroups.push(element.name);
     this.elementsDataExtensions = objectData.filter(data => data.type == 0);
     this.elementGroupsDIDs.push(element.name);
     this.elementsDataDIDs = objectData.filter(data => data.type == 1);
    }
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
 },
 watch: {
  groupName: function (val) {
   this.$forceUpdate();
  },
  hostName: function (val) {
   this.getElementGroups();
  },
  urlType: function (val) {
   this.openForm = false;
   this.selectedPbxElements.length = 0;
  },
  openUpdateForm: function (val) {
   if (!val) {
    this.selectedPbxElements.length = 0;
   }
  },
 },
 mounted() {
  this.getElementGroups();
 },
 computed: {
  ...mapGetters(["hostName"]),
 },
};
</script>

<style></style>
